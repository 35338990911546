.App {
  text-align: center;
}
body {
  font-family: Helvetica;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.leaflet-control-zoom {
  position: absolute !important; /* Ensures that position is not sticky or fixed relative to the viewport */
}
.leaflet-touch .leaflet-bar {
  display: none;
}
.leaflet-touch .leaflet-control-attribution {
  display: none;
}
:focus {
  outline: none;
}
.app {
  transition: background-color 0.3s ease;
}

.light-mode {
  background-color: #fff;
  color: #000;
}

.dark-mode {
  background-color: #333;
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* MENU */
.menu {
  width: 100%;
  display: block;
  text-align: center;
  padding: 0px;
  color: black;
}
.menu ul {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0px;
  font-size: 18px;
  padding: 0px;
}
.menu li {
  list-style: outside none none;
  margin: 10px 0px;
  padding: 0;
  cursor: pointer;
  color: black;
  font-size: 28px;
}
.menu a {
  color: black;
}
.menu a:hover {
  color: #0f6c74;
}
.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 25px;
  z-index: 9999;
  background: white;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
  transition: 0.4s;
}
.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}
.burger-menu.open .bar2 {
  opacity: 0;
}
.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

@media (min-width: 450px) {
  .burger-menu,
  .burger-menu.open {
    display: none;
  }
}
