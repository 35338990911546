.header {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0; /* This makes the header stick to the top of the viewport */
  z-index: 1000; /* Ensure the header is above other content */
  background-color: #fff; /* Optional: Set a background color to prevent content from showing through */
}

header img {
  width: 300px;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Adjust as needed */
}

.logo {
  margin: 0 10px; /* Adjust as needed */
}

.menuu {
  padding-top: 10px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
}
.menuu ul {
  display: flex;
  flex-direction: row;
  list-style: none;
}
.menuu li a {
  margin: 0 10px; /* Adjust as needed */
  color: black;
  text-decoration: none;
  font-weight: 700;
}

.menuu li.active a {
  text-decoration: underline;
}

@media (max-width: 450px) {
  header img {
    width: 150px;
  }
  .menuu ul {
    display: none;
  }
  .logo-container {
    padding-right: 20px;
  }
}
