.concept-tab {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  margin-top: 50px;
}

.header-area h1 {
  margin-top: 0px;
  font-size: 2.5rem;
  color: #5d4037; /* A warm, earthy tone for a welcoming vibe */
  margin-bottom: 0.5em;
}

.header-area p {
  font-size: 1.2rem;
  color: #757575; /* Soft color for the introduction text */
}

.content-area {
  display: flex;
  flex-direction: column; /* Switch to 'row' if you want side by side layout for larger screens */
  align-items: center;
  margin-top: 2em;
}

.restaurant-description {
  flex: 1;
  margin-bottom: 2em; /* Space between text and images */
}
.restaurant-description p {
  line-height: 33px;
}
.cta-button {
  background-color: #a1887f; /* Color that stands out yet fits the Italian theme */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1em; /* Space above the button */
}

.image-gallery {
  display: flex;
  flex-direction: column; /* Stack images on small screens */
  gap: 10px; /* Space between images */
}

.image-gallery img {
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .content-area {
    flex-direction: row;
    align-items: flex-start;
  }

  .restaurant-description,
  .image-gallery {
    flex: 1;
  }

  .image-gallery {
    gap: 20px;
  }
}

@media (min-width: 1024px) {
  .restaurant-description {
    margin-right: 2em;
  }
}
