.footer-wrapper {
  width: 95%; /* Adjusts the width to be less than the full page width */
  margin: 100px auto; /* Adds margin to the top and bottom, auto centers it horizontally */
}

.footer {
  background-image: url("../../assets/unnamed.jpg"); /* Replace with the path to your image */
  background-size: cover; /* This will cover the footer area while maintaining aspect ratio */
  background-position: center; /* This centers the image in the footer */
  background-repeat: no-repeat; /* Prevents the image from tiling */
  height: 350px; /* Adjust the height as necessary */
}
@media (max-width: 450px) {
  .footer {
    height: 250px;
  }
}
