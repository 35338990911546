.home-container {
  padding-top: 80px;
  width: 85%;
  margin: 0 auto;
}
.home-container h1 {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 20px;
  line-height: 45px;
  text-align: left;
  width: 45%;
  margin: 0 auto;
}
.home-container .images {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.home-container .images .im {
  margin: 0 10px;
  width: calc(33.333% - 20px);
}
.home-container .hours {
  padding-top: 50px;
  padding-bottom: 100px;
}
.home-container .hours h2 {
  text-align: center;
  font-size: 40px;
  text-decoration: underline;
}
.home-container .map {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 50px;
}
.map .map-details {
  width: 50%;
  padding-right: 40px;
}
.map h3 {
  font-size: 35px;
}
.online-order {
  height: 45px;
  border: none;
  background-color: black;
  color: white;
  font-weight: 550;
  font-size: 18px;
  width: 250px;
  cursor: pointer;
  border-radius: 8px;
}

@media (max-width: 450px) {
  .home-container .images {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  .home-container .images .im {
    margin: 0 0px;
    width: 100%;
    padding-bottom: 30px;
  }
  .home-container .hours h2 {
    text-align: left;
    font-size: 20px;
    text-decoration: underline;
    margin-top: 0px;
  }
  .map h3 {
    font-size: 35px;
    margin: 5px;
  }
  .map .map-details {
    width: 100%;
    margin: 0 auto;
    padding-right: 0px;
    max-height: 300px;
  }
  .leaflet-container {
    height: 300px;
  }
  .home-container .map {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .home-container {
    padding-top: 40px;
  }
  .home-container h1 {
    width: 90%;
  }
  .home-container .hours {
    padding-top: 0px;
  }
  .home-container .map {
    padding-bottom: 10px;
  }
  .location-1 {
    padding-bottom: 40px;
  }
}

@media (max-width: 1300px) {
}
