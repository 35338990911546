.menu-container {
  width: 95%;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;
}
.menu-container a {
  color: black;
}
.menu-section,
.menus {
  margin-bottom: 20px;
}

.menus {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap; /* Allows the menus to stack on smaller screens */
}

.menu-image {
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Adds some shadow for depth */
}

/* If the images are too large, you can restrict their size */
.regular-menu img,
.vegan-menu img {
  max-width: 600px; /* Adjust this as needed */
}

@media screen and (max-width: 768px) {
  .menus {
    flex-direction: column;
  }

  .regular-menu img,
  .vegan-menu img {
    max-width: 100%;
  }
}

.interactive-menu {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons button {
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.filter-buttons button.active {
  background-color: #f0f0f0;
}

.menu-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.menu-item {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}

.new-badge {
  background-color: #ff4136;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-left: 5px;
}

.price {
  font-weight: bold;
  margin-top: 10px;
}

.pdf-link {
  text-align: center;
  margin-top: 20px;
}

.pdf-link a {
  display: inline-block;
  padding: 10px 15px;
  background-color: #0074d9;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}
