.reserv-container {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 150px;
}
.reserv-container .reserve {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: 60%;
  margin: 0 auto;
}
.reserv-container .reservation-form {
  padding-top: 150px;
  width: 60%;
  margin: 0 auto;
}
.reservation-form input {
  width: 50%;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 50px;
  background-color: #f7f7f7;
  border: 1px solid black;
}
.reservation-form textarea {
  width: 50%;
  height: 60px;
  background-color: #f7f7f7;
  border: 1px solid black;
  margin-right: 20px;
  margin-bottom: 30px;
}
.contact-info a {
  color: black !important;
  text-decoration: none !important;
}
.reservation-form button {
  height: 40px;
  border: none;
  background-color: black;
  color: white;
  font-weight: 550;
  font-size: 18px;
  width: 200px;
  cursor: pointer;
}
.reserve .res-button {
  width: 300px;
  margin: 0 auto;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  background-color: #f2180a;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
}
.reserve .res-button:hover {
  background-color: #ef3e34;
}
.reserv-container h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 40px;
}
.reserv-container .contact-info {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
}

.phone-input-container {
  margin-bottom: 15px;
}

.phone-input {
  padding: 10px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.phone-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50; /* Green background */
  color: white;
  cursor: pointer;
}

.phone-submit:hover {
  background-color: #45a049; /* Darker green on hover */
}

.large-party-info p {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  line-height: 33px;
}
.row {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
}

@media (max-width: 450px) {
  .reserv-container {
    width: 100%;
    margin: 0 auto;
  }
  .reserv-container .reserve {
    padding-top: 100px;
    width: 80%;
    margin: 0 auto;
  }

  .reserv-container h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 40px;
  }
  .reserv-container .contact-info {
    width: 90%;
  }
  .reserv-container .reservation-form {
    padding-top: 50px;
    width: 90%;
  }
}
