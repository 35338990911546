/* Booking.css */
.booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.calendar-container {
  width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 150px;
}

.calendar-container h2 {
  text-align: center;
  padding-bottom: 20px;
}
.calendar-container h3 {
  padding-top: 40px;
  text-align: center;
}
.calendar-container .time-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  gap: 10px; /* This adds space between the buttons */
  max-width: 60%;
  margin: 0 auto;
}
.calendar-container form {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
}
.calendar-container form label {
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}
.calendar-container form input {
  margin-right: 20px;
  margin-bottom: 20px;
  height: 50px;
  background-color: #f7f7f7;
  border: 1px solid black;
}
.calendar-container form select {
  margin-right: 20px;
  margin-bottom: 20px;
  height: 50px;
  background-color: #f7f7f7;
  border: 1px solid black;
}
.calendar-container form button {
  margin-top: 20px;
  height: 40px;
  border: none;
  background-color: #f2180a;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.time-slot {
  margin: 5px;
  padding: 10px 20px;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover effect */
}

.time-slot.selected,
.time-slot:hover:not(.selected) {
  border-color: #007bff;
  background-color: #e7f0fd;
}

/* Add styles for the calendar */
.calendar-container .react-calendar {
  display: block;
  margin: 0 auto;
  border: none;
  min-width: 80%;
  background-color: #f2180a;
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Style the navigation buttons */
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

/* Style the days */
.react-calendar__month-view__days__day {
  padding: 8px 0;
  transition: background-color 0.3s;
}

/* Highlight today */
.react-calendar__tile--now {
  background: #00d1a0;
  color: white;
}

/* Style the active day */
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.thank-you-page {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  color: black;
}

.thank-you-page h1 {
  font-size: 3rem;
  margin-bottom: 0.5em;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.thank-you-page p {
  font-size: 1.5rem;
  margin-bottom: 1em;
  color: black;
  max-width: 600px; /* Keeps the text from being too wide on larger screens */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 450px) {
  .calendar-container {
    width: 90%;
    margin: 0 auto;
  }
  .thank-you-page h1 {
    padding-inline: 10px;
  }
  .thank-you-page p {
    padding-inline: 20px;
  }
  .calendar-container h2 {
    text-align: left;
    font-size: 20px;
  }
  .time-slots {
    padding: 0px;
    padding-bottom: 50px;
  }
  .calendar-container form {
    display: flex;
    flex-direction: column;
  }
  .calendar-container form input {
    margin-right: 20px;
    margin-bottom: 20px;
    height: 50px;
    background-color: #f7f7f7;
    border: 1px solid black;
  }
  .calendar-container .time-slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    gap: 10px; /* This adds space between the buttons */
    max-width: 95%;
  }
  .react-calendar {
    border: none;
    min-width: 350px;
    display: block;
    margin: 0 auto;
  }
  .calendar-container form {
    padding-top: 0px;
  }
  .calendar-container form button {
    width: 80%;
    margin: 0 auto;
  }
}
